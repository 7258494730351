import {
  ExternalOption,
  Option,
  SecondaryInstrument,
  ShirtSize,
} from "@prisma/client";
import { z } from "zod";
import { cmqConfig } from "../cmqConfig";
import { DiscountsSchema, PricesSchema } from "./PricesSchema";
import { emptyStringToNull } from "./schemaUtils";
import type { HealthInfo } from "./HealthInfoSchema";

export const CamperInputSchema = z
  .object({
    firstName: z.string().trim(),
    lastName: z.string().trim(),
    birthDate: z.coerce.date(),
    gender: z.enum(["M", "F"]),
    option: z
      .preprocess(emptyStringToNull, z.nativeEnum(Option).nullable())
      .refine(
        (opt) => !cmqConfig.blockOptions.includes(opt!),
        "Malheureusement, il n'y a plus de places dans l'option sélectionnée."
      ),
    secondaryInstrument: z
      .preprocess(
        emptyStringToNull,
        z.nativeEnum(SecondaryInstrument).nullable()
      )
      .refine(
        (secIns) => !cmqConfig.blockSecondaryInstruments.includes(secIns!),
        "Malheureusement, il n'y a plus de places pour l'instrument secondaire sélectionné."
      ),
    externalOptions: z.array(z.nativeEnum(ExternalOption)),
    chamberMusicGroup: z.string().trim().nullish(),
    teacher: z.string().trim(),
    teachingPlace: z.string().trim(),
    yearsOfExperience: z.number().positive(),
    lastPieces: z.string().trim(),
    hasShirt: z.boolean().default(false),
    shirtSize: z.nativeEnum(ShirtSize).nullable(),
    paymentId: z.number().nullish(),

    notes: z.string().trim(),
  })

  // Ensure chamber music group is needed
  .refine(
    ({ externalOptions, chamberMusicGroup }) =>
      !(externalOptions.includes("STAGE_PRO_MC") && !chamberMusicGroup),
    {
      message:
        "Vous devez entrer les autres membres de votre groupe de musique de chambre",
      path: ["chamberMusicGroup"],
    }
  )

  // Ensure shirt size is selected
  .transform((camper, ctx) => {
    if (!camper.hasShirt && !camper.shirtSize) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Vous devez sélectionner une taille de gilet",
      });
      return z.NEVER;
    } else if (camper.hasShirt) {
      camper.shirtSize = null;
    }
    return camper;
  })

  // Ensure option is set
  .refine(
    ({ option, externalOptions }) =>
      !(option == null && externalOptions.length === 0),
    {
      message:
        "Vous devez sélectionner soit une option standard ou une option externe.",
      path: ["option"],
    }
  )

  // Prevent both stage pro and standard option being set at once
  .refine(
    ({ option, externalOptions }) =>
      !(
        option != null &&
        externalOptions.some((opt) =>
          ["STAGE_PRO", "STAGE_PRO_MC"].includes(opt)
        )
      ),
    {
      message:
        "Vous ne pouvez pas vous inscrire à la fois à une option standard et au Stage Pro.",
      path: ["externalOptions"],
    }
  );

export type CamperInput = z.infer<typeof CamperInputSchema>;

export const CamperSchema = CamperInputSchema.and(
  z.object({
    id: z.number(),
    totalPrice: z.number().nullable(),
    prices: PricesSchema.nullable(),
    discounts: DiscountsSchema.nullable(),
  })
);

export type Camper = z.infer<typeof CamperSchema>;

export type CamperWithHealthInfo = Camper & {
  healthInfo: HealthInfo | null;
};
