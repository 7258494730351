import { sum } from "lodash";
import { PopulatedRegistration } from "../schemas/RegistrationSchema";
import {
  hasBlockedOption,
  hasBlockedSecondaryInstrument,
  isComplete,
  isPaid,
} from "./camperUtils";

export const isEveryCamperComplete = ({
  contactInfo,
  campers,
}: PopulatedRegistration) => {
  return (
    contactInfo !== null &&
    campers.length > 0 &&
    campers.every((camper) => isComplete(camper))
  );
};

export const isReadyToPay = (registration: PopulatedRegistration) =>
  isEveryCamperComplete(registration) && !hasBlockedItems(registration);

export const getBalance = (registration: PopulatedRegistration) => {
  return (
    registration.totalPrice - sum(registration.payments.map((p) => p.amount))
  );
};

export const hasBlockedItems = (registration: PopulatedRegistration) =>
  registration.campers.some(
    (camper) =>
      !isPaid(camper) &&
      (hasBlockedOption(camper) || hasBlockedSecondaryInstrument(camper))
  );
