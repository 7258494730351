import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { Camper } from "../../../../../shared/schemas/CamperSchema";
import HealthInfoSchema, {
  HealthInfo,
} from "../../../../../shared/schemas/HealthInfoSchema";
import { healthInsuranceMask } from "../../../../../shared/schemas/textMasks";
import { fullName, ilElle } from "../../../../../shared/utils/camperUtils";
import useRouterYear from "../../hooks/useRouterYear";
import { poster } from "../../utils/api";
import { buttonClassname } from "../Button";
import ErrorMessage from "./ErrorMessage";
import { FormGroup, FormSubHeading } from "./formUtils";
import YesNoRadio from "./YesNoRadio";

export type HealthInfoFormProps = {
  camper: Camper;
  healthInfo: HealthInfo | null;
};
export default function HealthInfoForm({
  camper,
  healthInfo,
}: HealthInfoFormProps) {
  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors },
  } = useForm<HealthInfo>({
    resolver: zodResolver(HealthInfoSchema),
    defaultValues: healthInfo || {
      authorizesCare: true,
      authorizesMedia: true,
    },
  });

  const year = useRouterYear();
  const navigate = useNavigate();

  useEffect(() => console.log("Errors", errors), [errors]);
  const onSubmit = handleSubmit(async (data) => {
    console.log(data);
    try {
      const response = await poster(`/camper/${camper.id}/health-info`, data);
      if (response.status === 200) {
        toast.success(successToast(fullName(camper)));
        navigate(`/${year}`);
      }
    } catch (error) {
      toast.error(errorToast(fullName(camper)));
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <FormSubHeading>Assurance maladie</FormSubHeading>
      <ErrorMessage errors={errors} name="healthInsuranceNumber" />
      <ErrorMessage errors={errors} name="healthInsuranceExpirationMonth" />
      <ErrorMessage errors={errors} name="healthInsuranceExpirationYear" />
      <div className="flex space-x-4">
        <FormGroup className="flex-grow">
          <label htmlFor="healthInsuranceNumber">
            Numéro d&apos;assurance maladie
          </label>
          <InputMask
            type="text"
            mask={healthInsuranceMask}
            maskPlaceholder={null}
            placeholder="ABCD 1234 5678"
            beforeMaskedStateChange={({ nextState }) => ({
              ...nextState,
              value: nextState.value.toUpperCase(),
            })}
            {...register("healthInsuranceNumber")}
          />
        </FormGroup>
        <FormGroup>
          <label>Expiration</label>
          <div className="flex space-x-2">
            <input
              type="number"
              placeholder="Ann"
              className="w-16"
              {...register("healthInsuranceExpirationYear", {
                valueAsNumber: true,
              })}
            />
            <input
              type="number"
              placeholder="M"
              className="w-11 text-center"
              {...register("healthInsuranceExpirationMonth", {
                valueAsNumber: true,
              })}
            />
          </div>
        </FormGroup>
      </div>
      <FormSubHeading>Informations santé</FormSubHeading>
      <FormGroup>
        <label htmlFor="takesMedicine">
          {camper.firstName} prend-{ilElle(camper)} des médicaments?
        </label>
        <ErrorMessage errors={errors} name="takesMedicine" />
        <YesNoRadio name="takesMedicine" control={control} />
      </FormGroup>
      <FormGroup>
        <label htmlFor="medicineInfo">Information sur les médicaments</label>
        <ErrorMessage errors={errors} name="medicineInfo" />
        <textarea {...register("medicineInfo")} />
      </FormGroup>
      <FormGroup>
        <label htmlFor="hasDisease">
          {camper.firstName} souffre-t-{ilElle(camper)} d&apos;une/plusieurs
          maladie(s)?
        </label>
        <ErrorMessage errors={errors} name="hasDisease" />
        <YesNoRadio name="hasDisease" control={control} />
      </FormGroup>
      <FormGroup>
        <label htmlFor="diseaseInfo">Information sur les maladies</label>
        <ErrorMessage errors={errors} name="diseaseInfo" />
        <textarea {...register("diseaseInfo")} />
      </FormGroup>
      <FormGroup>
        <label htmlFor="hasAllergies">
          {camper.firstName} a-t-{ilElle(camper)} des allergies?
        </label>
        <ErrorMessage errors={errors} name="hasAllergies" />
        <YesNoRadio name="hasAllergies" control={control} />
      </FormGroup>
      <FormGroup>
        <label htmlFor="allergyInfo">Informations sur les allergies</label>
        <ErrorMessage errors={errors} name="allergyInfo" />
        <textarea {...register("allergyInfo")} />
      </FormGroup>
      <FormGroup>
        <label htmlFor="healthComments">
          Commentaires généraux sur la santé de {camper.firstName}
        </label>
        <ErrorMessage errors={errors} name="healthComments" />
        <textarea {...register("healthComments")} />
      </FormGroup>
      <FormSubHeading>Autorisations</FormSubHeading>
      <FormGroup>
        <label htmlFor="authorizesCare">
          Autorisez-vous que nous administerions des soins à {camper.firstName}{" "}
          en cas d&apos;urgence?
        </label>
        <ErrorMessage errors={errors} name="authorizesCare" />
        <YesNoRadio name="authorizesCare" control={control} />
      </FormGroup>
      <FormGroup>
        <label htmlFor="authorizesMedia">
          Autorisez-vous la prise de photos à des fins promotionnelles?
        </label>
        <ErrorMessage errors={errors} name="authorizesMedia" />
        <YesNoRadio name="authorizesMedia" control={control} />
      </FormGroup>
      <input
        type="submit"
        className={clsx(
          "font-medium font-display",
          buttonClassname({ color: "blue", buttonStyle: "thick" })
        )}
      />
    </form>
  );
}

const successToast = (name: string) => (
  <span>
    La fiche santé de <strong>{name}</strong> a bien été enregistrée.
  </span>
);

const errorToast = (name: string) => (
  <span>
    Erreur dans l'enregistrement de la fiche santé de <strong>{name}</strong>.
  </span>
);
