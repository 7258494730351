import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { fullName } from "../../../../shared/utils/camperUtils";
import { getFrenchDateTimeString } from "../../../../shared/utils/dateUtils";
import { currency } from "../../../../shared/utils/formatCurrency";
import {
  getBalance,
  isReadyToPay,
} from "../../../../shared/utils/registrationUtils";
import Button from "../components/Button";
import InscriptionHeader from "../components/InscriptionHeader";
import Prices from "../components/Prices";
import { CenterWrap } from "../components/containers/CenterWrap";
import useRegistrationQuery from "../hooks/useRegistrationQuery";
import useRouterYear from "../hooks/useRouterYear";
import useScript from "../hooks/useScript";
import { poster } from "../utils/api";
import { encodedClientApiKey } from "../utils/paysafeUtils";
import { prettyJson } from "../../../../shared/utils/prettyUtils";

function closePaysafeIframe() {
  const paysafeIframe = document.querySelector("iframe#paysafe-checkout");
  paysafeIframe?.parentElement?.removeChild(paysafeIframe);
}

export default function PaymentPage() {
  const year = useRouterYear();

  const registrationQuery = useRegistrationQuery(year);
  const registration = registrationQuery.data;

  const balance = registration ? getBalance(registration) : 0;

  // Go back if registration is not ready to be paid
  const navigate = useNavigate();
  if (
    registrationQuery.isFetched &&
    registration &&
    !isReadyToPay(registration)
  ) {
    navigate(`/${registration.year}`);
    return null;
  }

  useScript(import.meta.env.VITE_PAYSAFE_CLIENT_SDK_URL);

  const captchaRef = useRef<ReCAPTCHA>(null);

  async function pay() {
    const recaptchaToken = captchaRef.current?.getValue();
    if (recaptchaToken == null || recaptchaToken == "") {
      return toast.error("Veuillez cocher la case «je ne suis pas un robot»");
    }

    window.paysafe.checkout.setup(
      encodedClientApiKey(),
      {
        amount: balance * 100,
        currency: "CAD",
        locale: "fr_CA",
        environment: import.meta.env.VITE_PAYSAFE_ENVIRONMENT,
        companyName: "Camp musical de Québec",
        imageUrl: "https://campmusicaldequebec.com/images/logo/logo-noir.png",
        buttonColor: "#46a8df",
        buttonLabel: "Payer mon inscription",
      },
      async (instance: any, error: any, result: any) => {
        try {
          if (!result || !result.token) {
            Sentry.captureException(error);
            throw new Error(
              "Result or result token undefined.\n" + prettyJson(error)
            );
          }
          if (result.paymentMethod !== "Cards") {
            toast.error("Méthode de paiement non supportée.");
            return;
          }
          console.log(result.token);

          const response = await poster(
            "/payment/paysafe",
            {
              amount: balance,
              paymentToken: result.token,
            },
            { recaptchaToken }
          );
          if (response && response.status === 200) {
            console.log("Success");
            console.log(response.data);
            window.location.href = `/inscription/${year}/succes`;
          } else {
            throw new Error("Did not get a positive response from server");
          }
        } catch (err) {
          closePaysafeIframe();
          console.log("ERROR");
          toast.error("Erreur dans le paiement. Veuillez réessayer plus tard.");
          captchaRef.current?.reset();
          console.error(err);
        }
      }
    );
  }

  if (registrationQuery.isInitialLoading) {
    return null;
  }

  return (
    <>
      <InscriptionHeader />
      <CenterWrap size="2xl" className="pt-3">
        <div className="mb-8 py-4 border-b">
          <div className="text-lg">
            <strong className="mr-3">Étape 2:</strong> Paiement
          </div>
        </div>
        {/* <div className="bg-azure-50 border border-azure-200 text-azure-700 font-bold rounded-md p-4 my-4 text-left">
          Nous sommes désolés, nous éprouvons présentement quelques problèmes
          avec notre système de paiement. Nous attendons présentement un
          correctif et le problème devrait être réglé dans quelques jours.
          Veuillez réessayer plus tard si vous obtenez un message d'erreur.
        </div> */}
        <div className="grid md_grid-cols-[minmax(0,1fr)_minmax(0,18rem)] gap-x-8 w-full">
          <div>
            <h3 className="font-bold font-display text-lg mb-4">Campeurs :</h3>
            <div>
              {registrationQuery.isFetched && (
                <Prices registration={registration!} />
              )}
            </div>
          </div>
          <div className="text-sm">
            <h3 className="font-bold font-display text-lg mb-4">Sommaire :</h3>
            <table className="w-full md_ml-auto">
              <tbody>
                {registration?.campers.map((camper) => (
                  <tr className="border-t border-b" key={camper.id}>
                    <th className="text-left text-slate-700 py-1">
                      {fullName(camper)}
                    </th>
                    <td className="text-right pl-4 align-bottom py-1">
                      {currency(camper.totalPrice!)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <th className="text-right pt-1 text-slate-600">Total : </th>
                  <td className="text-right pt-1 font-display font-bold text-base text-slate-700">
                    {currency(registration!.totalPrice)}
                  </td>
                </tr>
              </tbody>
            </table>

            {registration!.payments.length > 0 && (
              <div className="my-6">
                <h3 className="font-bold font-display mb-1">Paiements</h3>
                <table className="w-full">
                  <tbody>
                    {registration?.payments.map((payment) => (
                      <tr className="border-t border-b" key={payment.id}>
                        <th className="text-left text-slate-700 py-1">
                          {getFrenchDateTimeString(payment.date)}
                        </th>
                        <td className="text-right pl-4 align-bottom py-1">
                          {currency(payment.amount)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="my-8 text-right">
              <span>Balance: </span>
              <span
                className={clsx(
                  "text-2xl font-bold font-display ml-4 ",
                  balance > 0 ? "text-slate-700" : "text-green-700"
                )}
              >
                {currency(balance)}
              </span>
              {balance > 0 && (
                <div className="text-right">
                  <ReCAPTCHA
                    sitekey={import.meta.env.VITE_RECAPTCHA_CLIENT_KEY}
                    className="mt-4 recaptcha-right"
                    ref={captchaRef}
                  />
                  <Button
                    color={"blue"}
                    buttonStyle="thick"
                    className="mt-4 mx-auto text-base font-display font-medium"
                    onClick={() => pay()}
                  >
                    Procéder au paiement
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </CenterWrap>
    </>
  );
}
