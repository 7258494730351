import InscriptionHeader from "../components/InscriptionHeader";
import useUserQuery from "../hooks/useUserQuery";

export default function RegistrationCompletedPage() {
  const userQuery = useUserQuery();

  if (userQuery.isInitialLoading) {
    return null;
  }

  return (
    <>
      <InscriptionHeader />
      <canvas
        className="w-screen h-screen fixed top-0 left-0 pointer-events-none"
        id="confetti-canvas"
      ></canvas>
      <div className="small-center-wrap inscription-container text-center">
        <h1 className="section-title"></h1>
        <p className="text-xl font-bold font-display mb-2">
          Félicitations, votre paiement a bien été accepté!{" "}
          <i className="emoji">🎉</i>
        </p>
        <p className="text-lg">
          Un courriel de confirmation a été envoyé à{" "}
          <strong>{userQuery.data!.email}</strong>.
        </p>
        <a href="/" className="btn-gentle-blue mt-4">
          Retour à l'accueil
        </a>
      </div>
    </>
  );
}
