import { useQuery } from "@tanstack/react-query";
import { RegistrationResponse } from "../../../../server/inscription/controllers/RegistrationController";
import { getter } from "../utils/api";

export default function useRegistrationQuery(year: number) {
  const registrationQuery = useQuery<RegistrationResponse>({
    queryKey: ["registration", year],
    queryFn: () =>
      getter(`/registration/${year}`)
        .then((r) => r.data)
        .catch((_) => null),
  });
  return registrationQuery;
}
