import { differenceInYears } from "date-fns";
import {
  humanDiscounts,
  humanExternalOptions,
  humanOptions,
  humanPrices,
  humanSecondaryInstruments,
} from "./humanReadable";
import { cmqConfig } from "../cmqConfig";
import { Camper, CamperWithHealthInfo } from "../schemas/CamperSchema";
import { pickBy } from "lodash";

const SEPARATOR = String.fromCharCode(160) + "/ ";

export const fullName = (camper: Camper) =>
  `${camper.firstName} ${camper.lastName}`;

export const nameIncomplete = (camper: Camper) =>
  !camper.firstName || !camper.lastName;

export const getOptionString = (camper: Camper) =>
  camper.option ? humanOptions[camper.option] : "";

export const getSecondaryInstrumentString = (camper: Camper) =>
  camper.secondaryInstrument
    ? humanSecondaryInstruments[camper.secondaryInstrument]
    : "";

export const getExternalOptionsHumanArray = (camper: Camper) =>
  camper.externalOptions.map((opt) => humanExternalOptions[opt]);

export const getExternalOptionsString = (camper: Camper) =>
  getExternalOptionsHumanArray(camper).join(SEPARATOR);

export const getProgramme = (camper: Camper) =>
  [
    getOptionString(camper),
    getSecondaryInstrumentString(camper),
    getExternalOptionsString(camper),
  ]
    .filter((e) => e !== "")
    .join(SEPARATOR);

export const getAge = (camper: Camper) =>
  differenceInYears(new Date(), new Date(camper.birthDate));

export const ageAtBeginningOfCamp = (camper: Camper) =>
  differenceInYears(cmqConfig.firstDayOfCamp, new Date(camper.birthDate));

export const mustFillHealthInfo = (camper: Camper) =>
  ageAtBeginningOfCamp(camper) < 18;

export const isComplete = (camper: CamperWithHealthInfo) => {
  if (mustFillHealthInfo(camper) && camper.healthInfo == null) {
    return false;
  }
  return true;
};

export const isPaid = (camper: Camper) => {
  return camper.paymentId !== null;
};

export const mustHaveShirt = (camper: Camper) => camper.option != null;

export const ilElle = (camper: Camper) =>
  camper.gender === "M" ? "il" : "elle";

export const luiElle = (camper: Camper) =>
  camper.gender === "M" ? "lui" : "elle";

export function generatePricesDisplay(camper: Camper, promoCode?: string) {
  // prettier-ignore
  const prices = {
    [humanPrices["signupFee"]]:                            camper.prices!.signupFee,
    [getOptionString(camper)]:                             camper.prices!.basePrice,
    [humanPrices["shirtPrice"]]:                           camper.prices!.shirtPrice,
    [getSecondaryInstrumentString(camper)]:                camper.prices!.secondaryInstrumentPrice,
    [getExternalOptionsString(camper)]:                    camper.prices!.externalOptionsPrice,
    [humanDiscounts["dateDiscount"]]:                      -camper.discounts!.dateDiscount,
    [humanDiscounts["childDiscount"]]:                     -camper.discounts!.childDiscount,
    [humanDiscounts["promoDiscount"] + ` (${promoCode})`]: -camper.discounts!.promoDiscount,
  };

  return pickBy(prices, (price) => price !== 0);
}

export const hasBlockedOption = (camper: Camper) =>
  cmqConfig.blockOptions.includes(camper.option!);

export const hasBlockedSecondaryInstrument = (camper: Camper) =>
  cmqConfig.blockSecondaryInstruments.includes(camper.secondaryInstrument!);
