import { cmqConfig } from "../../../../shared/cmqConfig";
import { CenterWrap } from "../components/containers/CenterWrap";
import InscriptionHeader from "../components/InscriptionHeader";
import RegistrationSelector from "../components/RegistrationSelector";
import useRegistrationQuery from "../hooks/useRegistrationQuery";

const year = cmqConfig.currentRegistrationYear;

export default function AccueilPage() {
  const existingRegistrationQuery = useRegistrationQuery(year);

  const registration = existingRegistrationQuery.data || undefined;
  return (
    <>
      <InscriptionHeader />
      {!existingRegistrationQuery.isLoading && (
        <CenterWrap size="xl">
          <RegistrationSelector
            registration={registration}
            year={cmqConfig.currentRegistrationYear}
          />
        </CenterWrap>
      )}
    </>
  );
}
