// prettier-ignore
export const socialInsuranceMask = [/\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/];
export const socialInsuranceRegex = /^\d{3}\ \d{3}\ \d{3}$/;

// prettier-ignore
export const healthInsuranceMask = [/[A-Z]/i, /[A-Z]/i, /[A-Z]/i, /[A-Z]/i, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
export const healthInsuranceRegex = /^[A-Z]{4}\ \d{4}\ \d{4}$/;

// prettier-ignore
export const phoneNumberMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
export const phoneNumberRegex = /^\([1-9]\d{2}\)\ \d{3}-\d{4}$/;

// prettier-ignore
export const canadaPostalCodeMask = [/[ABCEGHJ-NPRSTVXY]/i, /\d/, /[ABCEGHJ-NPRSTV-Z]/i, " ", /\d/, /[ABCEGHJ-NPRSTV-Z]/i, /\d/];
export const canadPostalCodeRegex =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

export const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
export const zipCodeRegex = /^\d{5}$/;
