import clsx from "clsx";
import { PropsWithChildren } from "react";

export type NiceBoxProps = PropsWithChildren<{
  className?: string;
  type?: "normal" | "emphasis" | "success";
}>;

export const NiceBoxColors: {
  [key in NonNullable<NiceBoxProps["type"]>]: string;
} = {
  normal: "bg-slate-50 border-slate-200 text-slate-600",
  emphasis: "bg-azure-500 text-azure-50 border-azure-600 drop-shadow-lg",
  success: "bg-emerald-50 border-emerald-200 text-emerald-800",
};

export default function NiceBox({
  children,
  type = "normal",
  className,
}: NiceBoxProps) {
  return (
    <div
      className={clsx(
        "rounded-md border-2 p-4",
        NiceBoxColors[type],
        className
      )}
    >
      {children}
    </div>
  );
}
