import axios, { AxiosResponse } from "axios";

const baseUrl = "/inscription/api";

function makeUrl(requestedUrl: string) {
  return baseUrl + requestedUrl;
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log("Got 401 from api, redirecting to login...");
      window.location.assign("/inscription/user/login");
      return Promise.reject(error);
    }
  }
);

export async function getter<T = any>(url: string, params?: any) {
  const response = await axios.get<T>(makeUrl(url), { params });
  return response;
}
export async function poster<T = any>(url: string, body: any, params?: any) {
  const response = await axios.post<T>(makeUrl(url), body, { params });
  return response;
}

export async function putter<T = any>(url: string, body: any, params?: any) {
  const response = await axios.put<T>(makeUrl(url), body, { params });
  return response;
}

export async function deleter<T = any>(url: string, params?: any) {
  const response = await axios.delete<T>(makeUrl(url), { params });
  return response;
}
