import { useQuery } from "@tanstack/react-query";
import { PublicUser } from "../../../../shared/schemas/UserSchema";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import ProfileDropdown from "./ProfileDropdown";
import useUserQuery from "../hooks/useUserQuery";

export default function UserActions() {
  const { data, isLoading } = useUserQuery();
  const user = data;
  if (isLoading) {
    return null;
  }
  return (
    <div className="flex flex-row">
      <div className="flex flex-col text-right">
        <div className="font-bold text-slate-700">
          {user!.firstName} {user!.lastName}
        </div>
        <div className="text-sm text-slate-600">{user!.email}</div>
      </div>
      <div className="flex flex-col justify-center ml-3">
        <ProfileDropdown />
      </div>
    </div>
  );
}
