import { z } from "zod";
import { healthInsuranceRegex } from "./textMasks";

const HealthInfoSchema = z.object({
  healthInsuranceNumber: z
    .string()
    .regex(
      healthInsuranceRegex,
      "Le format du numéro d'assurance maladie est incorrect"
    ),
  healthInsuranceExpirationMonth: z.number().min(1).max(12),
  healthInsuranceExpirationYear: z.number(),
  takesMedicine: z.boolean(),
  medicineInfo: z.string().trim(),
  hasDisease: z.boolean(),
  diseaseInfo: z.string().trim(),
  hasAllergies: z.boolean(),
  allergyInfo: z.string().trim(),
  healthComments: z.string().trim(),
  authorizesCare: z.boolean(),
  authorizesMedia: z.boolean(),
});

export type HealthInfo = z.infer<typeof HealthInfoSchema>;
export default HealthInfoSchema;
