import { useQuery } from "@tanstack/react-query";
import { PublicUser } from "../../../../shared/schemas/UserSchema";

export default function useUserQuery() {
  return useQuery<PublicUser>({
    queryKey: ["user"],
    queryFn: async () => {
      const data = await fetch("/inscription/user");
      return data.json();
    },
    refetchOnWindowFocus: false,
  });
}
