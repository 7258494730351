import { Link } from "react-router-dom";
import useRouterYear from "../hooks/useRouterYear";
import { CenterWrap } from "./containers/CenterWrap";
import UserActions from "./UserActions";

export type InscriptionHeaderProps = {
  title?: string;
};

export default function InscriptionHeader({ title }: InscriptionHeaderProps) {
  const year = useRouterYear();

  const linkUrl = isNaN(year) ? "/accueil" : `/${year}`;
  const link = (
    <Link to={linkUrl} className="hover_underline">
      Inscription
      {!isNaN(year) && <span> {year}</span>}
    </Link>
  );

  return (
    <section className="border-b-2 bg-slate-50">
      <CenterWrap
        size="2xl"
        noYPadding
        className="py-6 flex flex-row justify-between items-center"
      >
        <div>
          <h1 className="font-bold text-2xl font-display text-slate-700">
            {title || link}
          </h1>
          {title && <div className="text-sm text-slate-500">{link}</div>}
        </div>
        <UserActions />
      </CenterWrap>
    </section>
  );
}
