import { differenceInDays, startOfToday } from "date-fns";
import { cmqConfig } from "../../../../shared/cmqConfig";

export default function useDiscountMessage() {
  const discountDeadline = cmqConfig.discountDeadline;

  const daysLeft = differenceInDays(discountDeadline, startOfToday());
  const discountEnded = new Date() > discountDeadline;
  let message = "";
  if (daysLeft === 0) {
    message = "Dernier jour pour profiter du rabais!";
  } else if (daysLeft === 1) {
    message = "Il reste 1 jour pour profiter du rabais!";
  } else {
    message = `Il reste ${daysLeft} jours pour profiter du rabais!`;
  }
  return {
    daysLeft,
    discountMessage: message,
    discountEnded: discountEnded,
  };
}
