import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { cmqConfig } from "../../../../../shared/cmqConfig";
import {
  Camper,
  CamperInput,
  CamperInputSchema,
} from "../../../../../shared/schemas/CamperSchema";
import {
  humanExternalOptions,
  humanGender,
  humanOptions,
  humanSecondaryInstruments,
  humanShirtSizes,
} from "../../../../../shared/utils/humanReadable";
import useRouterYear from "../../hooks/useRouterYear";
import { poster, putter } from "../../utils/api";
import { buttonClassname } from "../Button";
import Choices from "./Choices";
import ErrorMessage from "./ErrorMessage";
import { FormGroup, FormSubHeading } from "./formUtils";
import YesNoRadio from "./YesNoRadio";

type CamperFormProps = {
  camperData?: Camper;
};

export default function CamperForm({ camperData }: CamperFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm<CamperInput>({
    resolver: zodResolver(CamperInputSchema),
    defaultValues: {
      ...camperData,
      externalOptions: [],
      birthDate:
        camperData?.birthDate &&
        (new Date(camperData?.birthDate).toISOString().split("T")[0] as any),
    },
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const isEdit = camperData !== undefined;
  const year = useRouterYear();

  const option = watch("option") as string;

  const secondaryInstrument = watch("secondaryInstrument") as string;

  const externalOptions = watch("externalOptions") as false | string[];
  const showChamberGroup =
    externalOptions && externalOptions.includes("STAGE_PRO_MC");

  const hasShirt = watch("hasShirt");
  const shirtSize = watch("shirtSize");
  useEffect(() => {
    if (!hasShirt && shirtSize == undefined) {
      setValue("shirtSize", "E_XS");
    }
  }, [hasShirt, shirtSize]);

  const mustHaveShirt = option != "";

  const firstName = watch("firstName");

  const onSubmit = handleSubmit(async (data) => {
    console.log(data);
    const request = isEdit
      ? putter<Camper>(`/registration/${year}/camper/${camperData.id}`, data)
      : poster<Camper>(`/registration/${year}/camper`, data);
    try {
      const response = await request;
      if (response.status === 200) {
        const createdCamper = response.data;
        queryClient.invalidateQueries({
          queryKey: ["camper", createdCamper.id],
        });
        navigate(`/${year}`);
        const toastFunction = isEdit ? editToast : createToast;
        toast.success(
          toastFunction(`${createdCamper.firstName} ${createdCamper.lastName}`)
        );
      }
    } catch (error) {
      console.log("ERROR", error);
      toast.error("Erreur dans l'ajout du campeur");
    }
  });

  useEffect(() => {
    console.log("Form errors", errors);
  }, [errors]);

  return (
    <form onSubmit={onSubmit}>
      <FormSubHeading>Informations personnelles</FormSubHeading>
      <FormGroup>
        <label htmlFor="firstName">Prénom</label>
        <ErrorMessage errors={errors} name="firstName" />
        <input
          type="text"
          placeholder="Prénom du campeur"
          {...register("firstName")}
        />
      </FormGroup>
      <FormGroup>
        <label htmlFor="lastName">Nom</label>
        <ErrorMessage errors={errors} name="lastName" />
        <input
          type="text"
          placeholder="Nom du campeur"
          {...register("lastName")}
        />
      </FormGroup>
      <div className="mb-4 flex flex-row space-x-4 items-end">
        <div className="w-1/3 flex flex-col">
          <label htmlFor="gender">Sexe</label>
          <ErrorMessage errors={errors} name="gender" />
          <select {...register("gender")}>
            <Choices choices={humanGender} type="select" name="gender" />
          </select>
        </div>
        <div className="w-2/3 items-stretch flex flex-col">
          <label htmlFor="birthDate">Date de naissance</label>
          <ErrorMessage errors={errors} name="birthDate" />
          <input
            type="date"
            {...register("birthDate", { valueAsDate: true })}
            className="w-full"
          />
        </div>
      </div>

      <FormSubHeading>Options</FormSubHeading>
      <FormGroup>
        <label htmlFor="option">Option standard</label>
        <ErrorMessage errors={errors} name="option" />
        <select {...register("option")}>
          <Choices choices={humanOptions} type="select" name="option" />
        </select>
      </FormGroup>
      <FormGroup>
        <label htmlFor="secondaryInstrument">Instrument secondaire</label>
        <ErrorMessage errors={errors} name="secondaryInstrument" />
        <select {...register("secondaryInstrument")}>
          <Choices
            choices={humanSecondaryInstruments}
            type="select"
            name="secondaryInstrument"
          />
        </select>
        {cmqConfig.freeSecondaryInstrument && secondaryInstrument !== "" && (
          <div className="mt-2 text-sm font-display text-cardinal-600">
            N.B. - Les frais pour les cours d'instrument secondaire{" "}
            <strong>ne vous seront pas chargés immédiatement</strong> afin
            d'évaluer la faisabilité. Nous procédons par virement après le début
            du Camp.
          </div>
        )}
      </FormGroup>
      <FormGroup>
        <fieldset>
          <legend>Options externes</legend>
          <ErrorMessage errors={errors} name="externalOptions" />
          <Choices
            choices={humanExternalOptions}
            type="checkbox"
            register={register("externalOptions")}
          />
        </fieldset>
      </FormGroup>
      {showChamberGroup && (
        <FormGroup>
          <label htmlFor="chamberMusicGroup">
            Groupe de musique de chambre
          </label>
          <ErrorMessage errors={errors} name="chamberMusicGroup" />
          <textarea
            placeholder="Noms des autres membres du groupe de musique de chambre"
            {...register("chamberMusicGroup")}
          />
        </FormGroup>
      )}

      <FormSubHeading>Informations de niveau</FormSubHeading>
      <FormGroup>
        <label htmlFor="teacher">Professeur</label>
        <ErrorMessage errors={errors} name="teacher" />
        <input
          type="text"
          placeholder="Nom du professeur"
          {...register("teacher")}
        />
      </FormGroup>
      <FormGroup>
        <label htmlFor="teachingPlace">Lieu d&apos;enseignement</label>
        <ErrorMessage errors={errors} name="teachingPlace" />
        <input
          type="text"
          placeholder="Lieu d'enseignement"
          {...register("teachingPlace")}
        />
      </FormGroup>
      <FormGroup>
        <label htmlFor="yearsOfExperience">Années d&apos;expérience</label>
        <ErrorMessage errors={errors} name="yearsOfExperience" />
        <div className="flex items-center">
          <input
            type="number"
            className="w-12"
            {...register("yearsOfExperience", { valueAsNumber: true })}
          />
          <label className="ml-2">ans</label>
        </div>
      </FormGroup>
      <FormGroup>
        <label htmlFor="lastPieces">
          Pièces travaillées durant la dernière année
        </label>
        <ErrorMessage errors={errors} name="lastPieces" />
        <textarea {...register("lastPieces")} rows={4} />
      </FormGroup>

      <FormSubHeading className="!mb-2">
        Gilet du camp {mustHaveShirt && "(obligatoire)"}
      </FormSubHeading>
      {mustHaveShirt && (
        <p className="mb-3">
          Le gilet du camp fait partie de la tenue de concert et peut être
          réutilisé d'une année à l'autre.
        </p>
      )}
      <FormGroup className="sm_flex-row sm_items-center space-y-3 sm_space-y-0">
        <div className="sm_w-3/5 sm_py-3">
          <fieldset>
            <ErrorMessage errors={errors} name="hasShirt" />
            <YesNoRadio
              name="hasShirt"
              control={control}
              yesText={
                mustHaveShirt
                  ? (firstName ? `${firstName} a ` : "J'ai") + " déjà le gilet"
                  : "Je ne désire pas acheter un gilet"
              }
              noText={
                mustHaveShirt
                  ? (firstName ? `${firstName} n'a` : "Je n'ai") +
                    ` pas déjà le gilet`
                  : "Je désire acheter un gilet"
              }
              defaultValue={false}
              swapDirection
            />
          </fieldset>
        </div>
        {!hasShirt && (
          <div className="sm_w-2/5">
            <label htmlFor="shirtSize">Taille du gilet désirée</label>
            <ErrorMessage errors={errors} name="shirtSize" />
            <select {...register("shirtSize")} className="w-full">
              <Choices
                choices={humanShirtSizes}
                type="select"
                name="shirtSize"
              />
            </select>
          </div>
        )}
      </FormGroup>

      <FormGroup>
        <label htmlFor="notes">Notes (facultatif)</label>
        <ErrorMessage errors={errors} name="notes" />
        <textarea
          placeholder={
            "Ajoutez des notes spéciales " +
            (firstName ? `concernant ${firstName} ` : "") +
            "ici."
          }
          rows={5}
          {...register("notes")}
        />
      </FormGroup>
      <input
        type="submit"
        value="Soumettre"
        className={buttonClassname({
          color: "blue",
          buttonStyle: "thick",
          className: "w-full font-display font-medium",
        })}
      />
    </form>
  );
}

const createToast = (name: string) => (
  <span>
    <strong>{name}</strong> ajouté à la liste de campeurs!
  </span>
);

const editToast = (name: string) => (
  <span>
    <strong>{name}</strong> a été modifié avec succès!
  </span>
);
