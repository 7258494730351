import { format } from "date-fns";
import frLocale from "date-fns/locale/fr-CA";

export function getDateString(dateInput: Date | string) {
  const date = new Date(dateInput);
  const dateString = date.toISOString();
  return dateString.slice(0, dateString.indexOf("T"));
}

export function getFrenchDateTimeString(dateInput: Date | string) {
  const date = new Date(dateInput);
  return format(date, "d MMMM YYY - HH:mm", { locale: frLocale });
}

export function EDTDate(dateInput: string) {
  return new Date(dateInput + "T00:00:00.000-04:00");
}
