import type { ContactInfo } from "../../../../shared/schemas/ContactInfoSchema";
import { humanCountries } from "../../../../shared/utils/humanReadable";

export type ContactInfoProps = {
  contactInfo: ContactInfo;
};

export default function ContactInfoSummary({ contactInfo }: ContactInfoProps) {
  return (
    <div>
      <address className="not-italic text-slate-600 text-sm">
        <span className="font-extrabold text-slate-700">
          {contactInfo.firstName} {contactInfo.lastName}
        </span>
        <br />
        {contactInfo.address}
        <br />
        {contactInfo.city} ({contactInfo.province}) {contactInfo.postalCode}
        <br />
        {humanCountries[contactInfo.country]}
        <div className="my-2 border-b w-12"></div>
        {contactInfo.primaryPhone}
        <br />
        {contactInfo.secondaryPhone}
        <br />
        {contactInfo.socialInsuranceNumber && (
          <>
            <div className="my-2 border-b w-12"></div>
            NAS: XXX XXX XXX
          </>
        )}
      </address>
    </div>
  );
}
