import { CenterWrap } from "../components/containers/CenterWrap";
import CamperForm from "../components/forms/CamperForm";
import InscriptionHeader from "../components/InscriptionHeader";

export default function AddCamperPage() {
  return (
    <>
      <InscriptionHeader />
      <CenterWrap size="lg">
        <h1 className="section-title">Ajouter un campeur</h1>
        <CamperForm />
      </CenterWrap>
    </>
  );
}
