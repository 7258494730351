import type { Option, SecondaryInstrument } from "@prisma/client";
import { EDTDate } from "./utils/dateUtils";

interface ICmqConfig {
  currentRegistrationYear: number;
  discountDeadline: Date;
  firstDayOfCamp: Date;
  lastDayOfCamp: Date;
  blockSignup: boolean;
  blockOptions: Option[];
  blockSecondaryInstruments: SecondaryInstrument[];
  freeSecondaryInstrument: boolean;
}

export const cmqConfig: ICmqConfig = {
  currentRegistrationYear: 2024,
  discountDeadline: EDTDate("2024-03-19"),
  firstDayOfCamp: EDTDate("2024-06-25"),
  lastDayOfCamp: EDTDate("2024-07-13"),
  blockSignup: false,
  blockOptions: ["PN_reg", "PN_int", "PN_def"],
  blockSecondaryInstruments: ["PN_sec"],
  freeSecondaryInstrument: false,
};
