import { UserCircleIcon, UserIcon } from "@heroicons/react/20/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import countries from "i18n-iso-countries";
import { useEffect, useState } from "react";
import { useForm, UseFormRegisterReturn } from "react-hook-form";
import toast from "react-hot-toast";
import InputMask from "react-input-mask";
import provinces from "../../../../../shared/provinces.json";
import type { ContactInfo } from "../../../../../shared/schemas/ContactInfoSchema";
import { ContactInfoSchema } from "../../../../../shared/schemas/ContactInfoSchema";
import {
  canadaPostalCodeMask,
  phoneNumberMask,
  socialInsuranceMask,
  zipCodeMask,
} from "../../../../../shared/schemas/textMasks";
import { humanCountries } from "../../../../../shared/utils/humanReadable";
import useRouterYear from "../../hooks/useRouterYear";
import { poster } from "../../utils/api";
import Button, { buttonClassname } from "../Button";
import ContactInfoSummary from "../ContactInfoSummary";
import Choices from "./Choices";
import ErrorMessage from "./ErrorMessage";
import { FormGroup } from "./formUtils";
import YesNoRadio from "./YesNoRadio";

export type ContactInfoFormProps = {
  contactInfo: ContactInfo | null;
};

export default function ContactInfoForm(props: ContactInfoFormProps) {
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<ContactInfo>({
    resolver: zodResolver(ContactInfoSchema),
    defaultValues: {
      country: "CA",
      province: "QC",
      wantsChildcareReceipt: false,
      ...props.contactInfo,
    },
  });

  const isEdit = props.contactInfo != null;

  const [formOpen, setFormOpen] = useState(false);

  const year = useRouterYear();

  const queryClient = useQueryClient();

  // useFormPersist("contactInfoForm", {
  //   watch,
  //   setValue,
  //   storage: typeof window !== "undefined" ? window.localStorage : undefined,
  //   exclude: ["socialInsuranceNumber"],
  // });

  const country = watch("country") as countries.Alpha2Code;

  const postalCodeMasks: { [key: string]: (string | RegExp)[] | undefined } = {
    US: zipCodeMask,
    CA: canadaPostalCodeMask,
  };
  const postalCodeMask = postalCodeMasks[country];

  const onSubmit = handleSubmit(async (data) => {
    console.log(data);
    try {
      const response = await poster(`/registration/${year}/contact-info`, data);
      if (response.status === 200) {
        toast.success("Information de contact ajoutée ou modifiée.");
        queryClient.invalidateQueries(["registration", year]);
        setFormOpen(false);
      }
    } catch (error) {
      toast.error("Erreur dans l'enregistrement de l'information de contact");
    }
  });

  const wantsChildcareReceipt = watch("wantsChildcareReceipt");

  useEffect(() => {
    if (!wantsChildcareReceipt) {
      setValue("socialInsuranceNumber", null);
    }
  }, [wantsChildcareReceipt]);

  if (isEdit && !formOpen) {
    return (
      <div>
        <ContactInfoSummary contactInfo={props.contactInfo!} />
        <Button
          buttonStyle="gentle"
          className="mt-4"
          onClick={() => setFormOpen(true)}
        >
          <UserCircleIcon className="w-4 mr-1" />
          Éditer l'info de contact
        </Button>
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <FormGroup>
        <label>Prénom</label>
        <ErrorMessage errors={errors} name="firstName" />
        <input
          type="text"
          placeholder="Prénom de la personne responsable"
          className="text-sm"
          {...register("firstName")}
        />
      </FormGroup>
      <FormGroup>
        <label>Nom</label>
        <ErrorMessage errors={errors} name="lastName" />
        <input
          type="text"
          placeholder="Nom de la personne responsable"
          className="text-sm"
          {...register("lastName")}
        />
      </FormGroup>
      <FormGroup>
        <label>Adresse</label>
        <ErrorMessage errors={errors} name="address" />
        <input
          type="text"
          placeholder="Adresse de la personne responsable"
          className="text-sm"
          {...register("address")}
        />
      </FormGroup>
      <FormGroup>
        <label>Ville</label>
        <ErrorMessage errors={errors} name="city" />
        <input
          type="text"
          placeholder="Ville"
          className="text-sm"
          {...register("city")}
        />
      </FormGroup>
      <FormGroup>
        <label>Province</label>
        <ErrorMessage errors={errors} name="province" />
        <ProvinceSelector country={country} register={register("province")} />
      </FormGroup>
      <FormGroup>
        <label>Pays</label>
        <ErrorMessage errors={errors} name="country" />
        <select {...register("country")} className="text-sm">
          <Choices type="select" choices={humanCountries} sortValues />
        </select>
      </FormGroup>
      <FormGroup>
        <label>Code postal</label>
        <ErrorMessage errors={errors} name="postalCode" />
        {postalCodeMask === undefined ? (
          <input type="text" className="text-sm" {...register("postalCode")} />
        ) : (
          <InputMask
            type="text"
            className="text-sm"
            mask={postalCodeMask}
            maskPlaceholder={null}
            beforeMaskedStateChange={({ nextState }) => ({
              ...nextState,
              value: nextState.value.toUpperCase(),
            })}
            {...register("postalCode")}
          />
        )}
      </FormGroup>
      <FormGroup>
        <label>Téléphone 1 (en cas d'urgence)</label>
        <ErrorMessage errors={errors} name="primaryPhone" />
        <InputMask
          type="text"
          placeholder="ex. (123) 456-7891"
          mask={phoneNumberMask}
          maskPlaceholder={null}
          className="text-sm"
          {...register("primaryPhone")}
        />
      </FormGroup>
      <FormGroup>
        <label>Téléphone 2</label>
        <ErrorMessage errors={errors} name="secondaryPhone" />
        <InputMask
          type="text"
          placeholder="ex. (123) 456-7891"
          mask={phoneNumberMask}
          maskPlaceholder={null}
          className="text-sm"
          {...register("secondaryPhone")}
        />
      </FormGroup>
      <FormGroup>
        <label className="mb-0">
          Émettre un reçu de frais de garde (relevé 24) ?
        </label>
        <p className="text-xs my-1 text-slate-500">
          (Pour crédit d'impôt. Enfants de moins de 16 ans.)
        </p>
        <ErrorMessage errors={errors} name="wantsChildcareReceipt" />
        <YesNoRadio name="wantsChildcareReceipt" control={control} />
      </FormGroup>
      {wantsChildcareReceipt && (
        <FormGroup>
          <label className="mb-0">Numéro d'assurance sociale</label>
          <p className="text-xs my-1 text-slate-600">
            (Pour reçu de frais de garde)
          </p>
          <ErrorMessage errors={errors} name="socialInsuranceNumber" />
          <InputMask
            type="text"
            placeholder="ex. 000 000 000"
            mask={socialInsuranceMask}
            maskPlaceholder={null}
            className="text-sm"
            {...register("socialInsuranceNumber")}
          />
        </FormGroup>
      )}
      <input
        type="submit"
        value="Soumettre l'info de contact"
        className={clsx(
          "font-medium font-display",
          buttonClassname({ color: "blue", buttonStyle: "thick" })
        )}
      />
    </form>
  );
}

type ProvinceSelectorProps = {
  country: countries.Alpha2Code;
  register: UseFormRegisterReturn;
};
function ProvinceSelector({ country, register }: ProvinceSelectorProps) {
  if (country === "CA" || country === "US") {
    return (
      <select {...register} className="text-sm">
        <Choices choices={provinces[country]} sortValues type={"select"} />
      </select>
    );
  }
  return <input type="text" className="text-sm" {...register} />;
}
