import clsx from "clsx";
import { Control, Controller } from "react-hook-form";

type YesNoRadioProps = {
  name: string;
  control: Control<any>;
  defaultValue?: boolean;
  yesText?: string;
  noText?: string;
  swapDirection?: boolean;
};

export default function YesNoRadio({
  name,
  control,
  defaultValue = false,
  yesText = "Oui",
  noText = "Non",
  swapDirection = false,
}: YesNoRadioProps) {
  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      render={({ field }) => (
        <div
          className={clsx(
            "flex",
            swapDirection ? "flex-col-reverse" : "flex-col"
          )}
        >
          <div>
            <input
              type="radio"
              name={name}
              value="true"
              onChange={() => field.onChange(true)}
              onBlur={field.onBlur}
              checked={field.value === true}
            />
            <label className="ml-3">{yesText}</label>
          </div>
          <div>
            <input
              type="radio"
              name={name}
              value="false"
              onChange={() => field.onChange(false)}
              onBlur={field.onBlur}
              checked={field.value === false}
            />
            <label className="ml-3">{noText}</label>
          </div>
        </div>
      )}
    />
  );
}
