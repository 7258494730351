import { z } from "zod";

export const PricesSchema = z.object({
  signupFee: z.number(),
  basePrice: z.number(),
  shirtPrice: z.number(),
  secondaryInstrumentPrice: z.number(),
  externalOptionsPrice: z.number(),
});

export type Prices = z.infer<typeof PricesSchema>;

export const DiscountsSchema = z.object({
  dateDiscount: z.number(),
  childDiscount: z.number(),
  promoDiscount: z.number(),
});

export type Discounts = z.infer<typeof DiscountsSchema>;
