import {
  ExternalOption,
  Gender,
  Option,
  SecondaryInstrument,
  ShirtSize,
} from "@prisma/client";
import countries from "i18n-iso-countries";
import frenchLocale from "i18n-iso-countries/langs/fr.json";
import prices from "../prices.json";
import { Discounts, Prices } from "../schemas/PricesSchema";

type Human<T> = { [key in keyof T]: string } & { ""?: string };

export const humanGender: Human<typeof Gender> = {
  M: "Masculin",
  F: "Féminin",
};

export const humanOptions: Human<typeof Option> = {
  "": "Aucun",

  EVEIL: "Éveil au violon",

  THEATRE: "Théâtre",

  VN_reg: "Violon Régulier",
  VN_int: "Violon Intermédiaire",
  VN_def: "Violon Défi",

  AL_reg: "Alto Régulier",
  AL_int: "Alto Intermédiaire",
  AL_def: "Alto Défi",

  VC_reg: "Violoncelle Régulier",
  VC_int: "Violoncelle Intermédiaire",
  VC_def: "Violoncelle Défi",

  CB_reg: "Contrebasse Régulier",
  CB_int: "Contrebasse Intermédiaire",
  CB_def: "Contrebasse Défi",

  PN_reg: "Piano Régulier",
  PN_int: "Piano Intermédiaire",
  PN_def: "Piano Défi",

  OR_def: "Orgue",

  CH_reg: "Chant",

  HP_reg: "Harpe Régulier",
  HP_int: "Harpe Intermédiaire",
  HP_def: "Harpe Défi",
};

export const humanSecondaryInstruments: Human<typeof SecondaryInstrument> = {
  "": "Aucun",
  VN_sec: "Violon secondaire",
  AL_sec: "Alto secondaire",
  VC_sec: "Violoncelle secondaire",
  CB_sec: "Contrebasse secondaire",
  PN_sec: "Piano secondaire",
  OR_sec: "Orgue secondaire",
  HP_sec: "Harpe secondaire",
  CH_sec: "Chant secondaire",
};

export const humanExternalOptions: Human<typeof ExternalOption> = {
  STAGE_PRO: "Stage Pro (violon)",
  STAGE_PRO_MC: "Stage Pro (musique de chambre)",
  PEDAGOGIE: "Session de pédagogie du violon",
};

export const humanShirtSizes: Human<typeof ShirtSize> = {
  E_XS: "Enfant - X-Small",
  E_S: "Enfant - Small",
  E_M: "Enfant - Med",
  E_L: "Enfant - Large",
  A_S: "Adulte - Small",
  A_M: "Adulte - Medium",
};

export const humanPrices: Human<Prices> = {
  signupFee: "Frais d'inscription",
  basePrice: "Prix de base",
  shirtPrice: "Prix du gilet",
  secondaryInstrumentPrice: "",
  externalOptionsPrice: "",
};
export const humanDiscounts: Human<Discounts> = {
  dateDiscount: `Inscription avant le ${prices.deadlineHumanReadable}`,
  childDiscount: "Rabais famille",
  promoDiscount: "Rabais promotionnel",
};

countries.registerLocale(frenchLocale);
export const humanCountries = countries.getNames("fr");
