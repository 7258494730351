import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Camper } from "../../../../shared/schemas/CamperSchema";
import { HealthInfo } from "../../../../shared/schemas/HealthInfoSchema";
import { CenterWrap } from "../components/containers/CenterWrap";
import HealthInfoForm from "../components/forms/HealthInfoForm";
import InscriptionHeader from "../components/InscriptionHeader";
import { getter } from "../utils/api";
import { fullName } from "../../../../shared/utils/camperUtils";

export default function EditHealthInfoPage() {
  const { camperId } = useParams();
  const camperQuery = useQuery<Camper>({
    queryKey: ["camper", camperId],
    queryFn: () => getter(`/camper/${camperId}`).then((r) => r.data),
  });
  const healthInfoQuery = useQuery<HealthInfo>({
    queryKey: ["camper", camperId, "healthInfo"],
    queryFn: () =>
      getter(`/camper/${camperId}/health-info`)
        .then((r) => r.data)
        .catch((_) => null),
  });

  const isLoading = healthInfoQuery.isLoading || camperQuery.isLoading;

  const camper = camperQuery.data!;
  const healthInfo = healthInfoQuery.data!;
  console.log(healthInfo);
  return (
    <>
      <InscriptionHeader />
      {!isLoading && (
        <CenterWrap size="lg">
          <h1 className="font-display font-bold text-2xl">
            Fiche santé de {fullName(camper)}
          </h1>
          <HealthInfoForm camper={camper} healthInfo={healthInfo} />
        </CenterWrap>
      )}
    </>
  );
}
