import {
  ArrowRightCircleIcon,
  InformationCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import {
  hasBlockedItems,
  isReadyToPay,
} from "../../../../shared/utils/registrationUtils";
import Button from "../components/Button";
import CamperBox from "../components/CamperBox";
import { CenterWrap } from "../components/containers/CenterWrap";
import NiceBox from "../components/containers/NiceBox";
import ContactInfoForm from "../components/forms/ContactInfoForm";
import InscriptionHeader from "../components/InscriptionHeader";
import useRegistrationQuery from "../hooks/useRegistrationQuery";
import useRouterYear from "../hooks/useRouterYear";

export default function RegistrationPage() {
  const navigate = useNavigate();
  const year = useRouterYear();
  if (Number.isNaN(year)) {
    navigate("/accueil");
  }

  const registrationQuery = useRegistrationQuery(year);
  if (registrationQuery.isFetched && !registrationQuery.data) {
    navigate("/accueil");
    return null;
  }

  if (registrationQuery.isLoading) {
    return null;
  }

  const registration = registrationQuery.data;
  const campers = registration!.campers;
  return (
    <>
      <InscriptionHeader />
      <CenterWrap size="2xl" className="pt-3">
        <div className="mb-8 py-4 border-b">
          <div className="text-lg">
            <strong className="mr-3">Étape 1:</strong> Entrez vos informations
          </div>
        </div>
        <div className="grid grid-cols-1 md_grid-cols-[minmax(0,18rem)_minmax(0,1fr)] gap-4 w-full">
          <div>
            <p className="font-bold font-display mb-4">
              Personne responsable :
            </p>
            <ContactInfoForm
              contactInfo={registrationQuery.data!.contactInfo}
            />
          </div>
          <div className="space-y-4 order-first md_order-none">
            <p className="font-bold font-display mb-4">Campeurs :</p>
            <NiceBox className="transition-colors hover_bg-azure-500 hover_text-azure-50 hover_border-azure-600">
              <Link
                to={`campeur/ajouter`}
                className="flex items-center justify-center p-12 font-display font-bold -m-4"
              >
                <PlusCircleIcon className="w-6 mr-2" />
                Ajouter un campeur
              </Link>
            </NiceBox>
            {campers.map((camper) => (
              <CamperBox
                key={camper.id}
                camper={camper}
                registration={registration!}
                healthInfo={camper.healthInfo}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col sm_flex-row space-y-4 space-x-4 items-end justify-end sm_items-baseline mt-8 py-4 border-t">
          {!isReadyToPay(registration!) && (
            <div className="text-sm text-slate-500">
              <InformationCircleIcon className="w-4 mr-1 -mt-1 inline-block" />
              {hasBlockedItems(registration!) ? (
                <span>
                  Un ou plusieurs campeurs a une option dans laquelle il ne
                  reste plus de place.
                </span>
              ) : (
                <span>
                  Veuillez remplir toutes les informations avant de continuer
                </span>
              )}
            </div>
          )}
          <Button
            className="font-medium font-display"
            buttonStyle="gentle"
            color="blue"
            disabled={!isReadyToPay(registration!)}
            onClick={() => navigate("paiement")}
          >
            Étape suivante <ArrowRightCircleIcon className="w-5 ml-1" />
          </Button>
        </div>
      </CenterWrap>
    </>
  );
}
