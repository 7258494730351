import { z } from "zod";
import {
  canadPostalCodeRegex,
  phoneNumberRegex,
  socialInsuranceRegex,
  zipCodeRegex,
} from "./textMasks";
export const ContactInfoSchema = z
  .object({
    firstName: z.string().trim(),
    lastName: z.string().trim(),
    address: z.string().trim(),
    city: z.string().trim(),
    province: z.string().trim(),
    country: z.string().trim(),
    postalCode: z.string().trim(),
    primaryPhone: z
      .string()
      .regex(
        phoneNumberRegex,
        "Le format du numéro de téléphone est invalide."
      ),
    secondaryPhone: z
      .string()
      .regex(
        phoneNumberRegex,
        "Le format du numéro de téléphone est invalide."
      ),
    wantsChildcareReceipt: z.boolean(),
    socialInsuranceNumber: z
      .string()
      .regex(
        socialInsuranceRegex,
        "Le format du numéro d'assurance sociale est invalide."
      )
      .nullable(),
  })
  .refine(
    ({ country, postalCode }) => {
      if (country === "US" && !zipCodeRegex.test(postalCode)) {
        return false;
      } else if (country === "CA" && !canadPostalCodeRegex.test(postalCode)) {
        return false;
      }
      return true;
    },
    { message: "Le code postal est invalide", path: ["postalCode"] }
  )
  .refine(
    ({ wantsChildcareReceipt, socialInsuranceNumber }) => {
      if (wantsChildcareReceipt && socialInsuranceNumber == null) {
        return false;
      }
      return true;
    },
    {
      message:
        "Vous devez entrer un numéro d'assurance sociale si vous désirez obtenir un relevé 24",
      path: ["socialInsuranceNumber"],
    }
  )
  .transform((contactInfo) => {
    if (!contactInfo.wantsChildcareReceipt) {
      contactInfo.socialInsuranceNumber = null;
    }
    return contactInfo;
  });

export type ContactInfo = z.infer<typeof ContactInfoSchema>;
