import { CheckIcon, ClockIcon } from "@heroicons/react/20/solid";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { RegistrationResponse } from "../../../../server/inscription/controllers/RegistrationController";
import { cmqConfig } from "../../../../shared/cmqConfig";
import useDiscountMessage from "../hooks/useDiscountMessage";
import { poster } from "../utils/api";
import NiceBox, { NiceBoxProps } from "./containers/NiceBox";

type RegistrationSelectorProps = {
  registration: RegistrationResponse | undefined;
  year: number;
};
const RegistrationSelector = ({
  registration,
  year,
}: RegistrationSelectorProps) => {
  const completed = registration?.completed;
  const isActiveYear = year === cmqConfig.currentRegistrationYear;
  const canComplete = isActiveYear && !completed;
  const exists = registration !== undefined;
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { discountMessage, discountEnded } = useDiscountMessage();

  const createRegistration = async () => {
    const response = await poster("/registration", { year });
    if (response.status === 200) {
      queryClient.invalidateQueries(["registration", year]);
      navigate(`/${year}`);
    }
  };

  const accessRegistrationButton = () => {
    const className = clsx(
      "bg-azure-700 text-white px-3 py-1 mt-1 rounded-md inline-block",
      completed ? "bg-emerald-500" : "bg-azure-700"
    );
    if (exists) {
      return (
        <Link to={`/${year}`} className={className}>
          Accéder à mon inscription
        </Link>
      );
    } else {
      return (
        <button className={className} onClick={() => createRegistration()}>
          S'inscrire maintenant
        </button>
      );
    }
  };

  let niceBoxType: NiceBoxProps["type"] = "normal";
  if (completed) {
    niceBoxType = "success";
  } else if (canComplete) {
    niceBoxType = "emphasis";
  }
  return (
    <NiceBox type={niceBoxType} key={year}>
      <h3
        className={clsx(
          "font-display font-bold",
          isActiveYear ? "text-3xl" : "text-2xl"
        )}
      >
        {year}
      </h3>
      {canComplete && (
        <>
          <p className="mt-2 mb-1">Période d'inscription en cours!</p>
          {!discountEnded && (
            <p className="mb-2 font-bold">
              <ClockIcon className="h-5 w-5 inline-block -mt-1 mr-2" />
              &nbsp;{discountMessage}
            </p>
          )}
        </>
      )}
      {completed && (
        <div className="flex items-center my-2">
          <div className="h-5 w-5 rounded-full bg-emerald-800 inline-flex justify-center items-center mr-2">
            <CheckIcon className="h-4 w-4 inline text-emerald-50" />
          </div>
          Inscription complétée
        </div>
      )}

      {!isActiveYear && (
        <p className="text-sm">Période d'inscription terminée</p>
      )}
      {accessRegistrationButton()}
    </NiceBox>
  );
};

export default RegistrationSelector;
