import InscriptionApp from "./InscriptionApp";
import * as ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://68cf7bd3baf74838949f6ae30ef72b32@o543461.ingest.sentry.io/5664078",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("inscription-app")!).render(
  <InscriptionApp />
);
