import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";

type CenterWrapProps = PropsWithChildren<{
  size?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";
  className?: string;
  noYPadding?: boolean;
}>;

const sizes: { [key in NonNullable<CenterWrapProps["size"]>]: string } = {
  sm: "max-w-sm",
  md: "max-w-md",
  lg: "max-w-lg",
  xl: "max-w-xl",
  "2xl": "max-w-2xl",
  "3xl": "max-w-3xl",
};

export const CenterWrap = ({
  size,
  children,
  className,
  noYPadding,
}: CenterWrapProps) => {
  size = size ?? "md";
  return (
    <section
      className={clsx(
        "w-full mx-auto px-4",
        noYPadding || "py-8",
        sizes[size],
        className
      )}
    >
      {children}
    </section>
  );
};
