import { ChevronUpIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";
import { RegistrationResponse } from "../../../../server/inscription/controllers/RegistrationController";
import { Camper } from "../../../../shared/schemas/CamperSchema";
import { Registration } from "../../../../shared/schemas/RegistrationSchema";
import { generatePricesDisplay } from "../../../../shared/utils/camperUtils";
import { currency } from "../../../../shared/utils/formatCurrency";
import Button from "./Button";
import { CamperOptions, CamperTitle } from "./CamperBox";
import NiceBox from "./containers/NiceBox";

export type PricesProps = {
  registration: RegistrationResponse;
};

export default function Prices({ registration }: PricesProps) {
  return (
    <div className="space-y-4 mb-8">
      {registration.campers.map((camper) => (
        <CamperPrices
          camper={camper}
          registration={registration}
          key={camper.id}
        />
      ))}
    </div>
  );
}

type CamperPricesProps = {
  camper: Camper;
  registration: Registration;
};
function CamperPrices({ camper, registration }: CamperPricesProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const prices = generatePricesDisplay(camper);
  return (
    <NiceBox>
      <div className="flex items-baseline">
        <CamperTitle camper={camper} />
      </div>
      <CamperOptions camper={camper} />
      <div className="flex flex-col items-end mt-4">
        <Button
          onClick={() => setIsMenuOpen((v) => !v)}
          color="gray"
          className="text-sm"
        >
          <span>
            <ChevronUpIcon
              className={clsx(
                "w-5 inline -mt-[2px] transition-all",
                !isMenuOpen && "rotate-180"
              )}
            />{" "}
            Afficher {isMenuOpen ? "moins" : "plus"} de détails
          </span>
        </Button>
        <div
          className={clsx("max-w-full overflow-hidden", !isMenuOpen && "h-0")}
        >
          <table className="max-w-full text-sm mt-3">
            <tbody>
              {Object.entries(prices).map(([title, amount]) => (
                <tr className="border-t border-b" key={title}>
                  <th className="text-left py-[0.15rem]">{title}</th>
                  <td className="text-right pl-3">{currency(amount)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="font-bold font-display mt-2 mr-[0.1rem]">
          {currency(camper.totalPrice!)}
        </div>
      </div>
    </NiceBox>
  );
}
