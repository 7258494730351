import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AccueilPage from "./pages/AccueilPage";
import AddCamperPage from "./pages/AddCamperPage";
import EditCamperPage from "./pages/EditCamperPage";
import EditHealthInfoPage from "./pages/EditHealthInfoPage";
import IndexPage from "./pages/IndexPage";
import PaymentPage from "./pages/PaymentPage";
import RegistrationSuccessPage from "./pages/RegistrationSuccessPage";
import RegistrationPage from "./pages/RegistrationPage";

// prettier-ignore
export function InscriptionApp() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/inscription">
        <Toaster />
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/accueil" element={<AccueilPage />} />
          <Route path="/:year" element={<RegistrationPage />} />
          <Route path="/:year/paiement" element={<PaymentPage />} />
          <Route path="/:year/succes" element={<RegistrationSuccessPage />} />
          <Route path="/:year/campeur/ajouter" element={<AddCamperPage />} />
          <Route path="/:year/campeur/:camperId/modifier" element={<EditCamperPage />} />
          <Route path="/:year/campeur/:camperId/fiche-sante" element={<EditHealthInfoPage />} />
          <Route path="*" element={<div>Not found</div>} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default InscriptionApp;
