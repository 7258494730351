import { CheckCircleIcon, HeartIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { Camper } from "../../../../shared/schemas/CamperSchema";
import { HealthInfo } from "../../../../shared/schemas/HealthInfoSchema";
import { Registration } from "../../../../shared/schemas/RegistrationSchema";
import { deleter } from "../utils/api";
import { isPaid } from "../../../../shared/utils/camperUtils";
import {
  fullName,
  getAge,
  getExternalOptionsHumanArray,
  getOptionString,
  getSecondaryInstrumentString,
  mustFillHealthInfo,
} from "../../../../shared/utils/camperUtils";
import Button, { buttonClassname } from "./Button";
import NiceBox from "./containers/NiceBox";

export type CamperBoxProps = {
  camper: Camper;
  registration: Registration;
  healthInfo: HealthInfo | null;
};
export default function CamperBox({
  camper,
  registration,
  healthInfo,
}: CamperBoxProps) {
  const navigate = useNavigate();
  const handleDeleteCamper = async (camper: Camper) => {
    const { id } = camper;
    try {
      const response = await deleter(
        `/registration/${registration.year}/camper/${id}`
      );
      if (response.status === 200) {
        toast.success(
          <span>
            <b>{fullName(camper)}</b> supprimé(e).
          </span>
        );
      }
    } catch (error) {
      console.log("Error while deleting camper", error);
      toast.error(
        <span>
          Impossible de supprimer <b>{fullName(camper)}</b>
        </span>
      );
    }
    // refresh();
  };

  const handleEditCamper = (camper: Camper) => {
    navigate(`/${registration.year}/campeur/${camper.id}/modifier`);
  };

  return (
    <NiceBox>
      <div className="flex items-baseline">
        <CamperTitle camper={camper} />
        {!isPaid(camper) && (
          <span className="ml-auto text-slate-500">
            <XMarkIcon
              className="w-7 cursor-pointer transition-all hover_bg-slate-500 hover_text-slate-200 rounded-full p-1 -my-2"
              onClick={() =>
                confirm(
                  `Voulez-vous vraiment supprimer ${fullName(camper)}?`
                ) && handleDeleteCamper(camper)
              }
            />
          </span>
        )}
      </div>

      <CamperOptions camper={camper} />
      <div className="justify-end items-baseline space-x-4 text-sm border-t-2 pt-3 mt-3 flex">
        {mustFillHealthInfo(camper) && (
          <Link
            to={`/${registration.year}/campeur/${camper.id}/fiche-sante`}
            className={buttonClassname({
              color: "gray",
              buttonStyle: "thick",
              className: "font-display font-medium mr-auto",
            })}
          >
            <HeartIcon className="w-4 inline-block mr-1 text-cardinal-400" />{" "}
            <span className="translate-y-[0.07rem]">
              {healthInfo === null
                ? "Remplir la fiche santé"
                : "Éditer la fiche santé"}
            </span>
          </Link>
        )}
        {isPaid(camper) ? (
          <div className="font-bold text-green-700">
            <CheckCircleIcon className="w-4 -mt-1 inline-block" /> Payé
          </div>
        ) : (
          <Button
            color="gray"
            buttonStyle="ghost"
            onClick={() => handleEditCamper(camper)}
          >
            Modifier
          </Button>
        )}
      </div>
    </NiceBox>
  );
}

export function CamperTitle({ camper }: { camper: Camper }) {
  return (
    <h2 className="overflow-hidden text-ellipsis">
      <span className="text-lg font-bold font-display text-slate-700">
        {fullName(camper)}
      </span>
      <span className="ml-2 text-sm font-display text-slate-500 whitespace-nowrap">
        {getAge(camper)} ans
      </span>
    </h2>
  );
}

export function CamperOptions({ camper }: { camper: Camper }) {
  return (
    <div className="space-y-1 mt-1 text-slate-500 text-sm">
      <p>{getOptionString(camper)}</p>
      <p>{getSecondaryInstrumentString(camper)}</p>
      {getExternalOptionsHumanArray(camper).map((opt) => (
        <p key={opt}>{opt}</p>
      ))}
    </div>
  );
}
