import _ from "lodash-es";
import { ReactNode } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

type ChoicesProps = {
  type: "checkbox" | "select" | "radio";
  name?: string;
  choices: {
    [key: string]: string;
  };
  register?: UseFormRegisterReturn;
  sortValues?: boolean;
};

export default function Choices(props: ChoicesProps) {
  const { choices, type, name, register, sortValues } = props;
  const entries = sortValues
    ? _.sortBy(Object.entries(choices), (entry) => entry[1].normalize("NFD"))
    : Object.entries(choices);
  return (
    <>
      {entries.map(([key, value]) =>
        choiceRenderers[type](key, value, name, register)
      )}
    </>
  );
}

type ChoiceRenderer = (
  key: string,
  value: string,
  name?: string,
  register?: UseFormRegisterReturn
) => ReactNode;
type ChoiceRenderers = { [key in ChoicesProps["type"]]: ChoiceRenderer };

const choiceRenderers: ChoiceRenderers = {
  checkbox: (key, value, _, register) => (
    <div key={key}>
      <input type="checkbox" value={key} {...register} />
      <label htmlFor={key} className="ml-3">
        {value}
      </label>
    </div>
  ),
  radio: (key, value, _, register) => (
    <div key={key}>
      <input type="radio" value={key} {...register} />
      <label htmlFor={key} className="ml-3">
        {value}
      </label>
    </div>
  ),
  select: (key, value) => (
    <option key={key} value={key}>
      {value}
    </option>
  ),
};
