import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Camper } from "../../../../shared/schemas/CamperSchema";
import { CenterWrap } from "../components/containers/CenterWrap";
import CamperForm from "../components/forms/CamperForm";
import InscriptionHeader from "../components/InscriptionHeader";
import { getter } from "../utils/api";

export default function AddCamperPage() {
  const { camperId } = useParams();
  const camperIdNumber = parseInt(camperId!);
  if (Number.isNaN(camperIdNumber)) {
    return null;
  }

  const camperQuery = useQuery<Camper>({
    queryKey: ["camper", camperIdNumber],
    queryFn: () =>
      getter<Camper>(`/camper/${camperIdNumber}`).then((r) => r.data),
  });

  if (camperQuery.isError) {
    return <div>Error fetching camper</div>;
  }

  return (
    <>
      <InscriptionHeader />
      <CenterWrap size="lg">
        <h1 className="section-title">Éditer un campeur</h1>
        {camperQuery.isLoading ? (
          <div>Chargement...</div>
        ) : (
          <CamperForm camperData={camperQuery.data} />
        )}
      </CenterWrap>
    </>
  );
}
