import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { cmqConfig } from "../../../../shared/cmqConfig";
import prices from "../../../../shared/prices.json";

export default function IndexPage() {
  return (
    <div className="max-w-3xl mx-auto my-16 sm_px-6 grid sm_grid-cols-[1fr,1.1fr] gap-x-8 gap-y-12">
      <div className="px-6 sm_px-0">
        <h1 className="font-bold font-display text-3xl leading-8 md_text-4xl mb-6 sm_mt-8">
          Inscription {cmqConfig.currentRegistrationYear}
        </h1>
        <div className="my-4 prose">
          <p>
            La période d'inscription pour le Camp musical de Québec{" "}
            {cmqConfig.currentRegistrationYear} est officiellement ouverte! 🎉
          </p>
          <p>
            Vous pouvez profiter d'un <b>rabais de {prices.dateDiscount} $</b>{" "}
            en vous inscrivant avant le <b>{prices.deadlineHumanReadable}</b>!
          </p>
          <p className="font-black">
            <ExclamationCircleIcon className="-mt-1 mr-1 w-5 inline-block" />{" "}
            Faites vite! Les places sont limitées!
          </p>
        </div>
        <div className="grid gap-4 grid-cols-2 max-w-sm mt-6">
          <a href="/inscription/user/signup" className="btn-gentle-blue">
            Créer un compte
          </a>
          <a href="/inscription/user/login" className="btn-gentle-blue">
            Me connecter
          </a>
        </div>
        <p className="mt-4 text-sm text-slate-500">
          Nouveauté cette année : réutilisez le même compte d'année en année!
        </p>
        <p className="mt-4 text-sm text-slate-500">
          Vous avez oublié votre mot de passe?
          <br />
          <a href="/inscription/user/forgot-password" className="cool-link">
            Réinitialisez-le en cliquant ici
          </a>
        </p>
      </div>
      <img
        src="/images/photos/inscription-index.jpg"
        className="sm_rounded-lg sm_shadow-lg"
      />
    </div>
  );
}
